import React from 'react';
import { HeadFC } from 'gatsby';

import Seo from '../../components/Seo';
import AgileTeams from '../../components/pages/Solutions/AgileTeams';

function TimesAgeis() {
  return <AgileTeams />;
}

export const Head: HeadFC = () => (
  <Seo title="Alocação de Times Ágeis" canonical="/solucoes/times-ageis" />
);

export default TimesAgeis;
