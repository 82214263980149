import React from 'react';

import Layout from '../../../Layout';
import Product from '../../shared/Product';
import { Solutions } from '../../../Icons/Values/solutions';
import Posts from '../../../Posts';

import Cover from '../../../../../static/solutions/alocacao.png';

function AgileTeams() {
  return (
    <Layout>
      <Product.Main
        cover={Cover}
        heading="Alocação de Times Ágeis"
        text={
          <>
            <span className="semi-bold italic">Soluções</span> tecnológicas 100%{' '}
            <span className="semi-bold italic">personalizadas</span> para sua
            empresa, desenvolvidas por equipes de{' '}
            <span className="semi-bold italic">alta performance</span> sob
            medida.
          </>
        }
        link="#contato"
      />

      <Product.TextSection
        heading={
          <>
            Squad as a Service{' '}
            <span className="semi-bold italic">accelerated by AI</span>
          </>
        }
        texts={[
          'Desenvolvimento de soluções tecnológicas por meio de outsourcing, com um time multidisciplinar de especialistas certificados em IA, altamente qualificados, com metodologia ágil exclusiva e infraestrutura completa.',
          'Cada squad é ajustado de maneira única e composto por profissionais que melhor atendem às necessidades específicas de cada empresa.  Dessa forma, você ganha escala de produção, segurança na integração de especialistas e maior eficiência na alocação de recursos mantendo qualidade de entrega e alta performance.',
          'Garanta resultados excepcionais com nossa abordagem ágil e especializada!'
        ]}
      />

      <Product.EmphasisText
        text={
          <>
            De soluções mais simples aos produtos digitais mais complexos, a
            Exactaworks está pronta para atender sua empresa com tudo que você
            precisa para{' '}
            <strong className="color--green italic">
              alavancar a produtividade do seu time de tecnologia, focar em
              estratégias e concentrar esforços no seu core business.
            </strong>
          </>
        }
      />

      <Product.List
        heading={
          <>
            Como a Exactaworks pode{' '}
            <span className="semi-bold italic">acelerar</span> o desenvolvimento
            de soluções tecnológicas na{' '}
            <span className="semi-bold italic">sua empresa</span>?
          </>
        }
        text={
          <>
            Combinando talentos humanos, inteligência artificial, métricas de
            eficiência e metodologias ágeis, nossas equipes estão preparadas
            para atender múltiplas demandas de cada cliente com soluções sob
            medida, de forma dedicada e 100% flexível, garantindo eficiência
            operacional e entregas excepcionais. <br />
            <strong className="color--pink">
              Confira um pouco do que já foi realizado pelos nossos times de
              alta performance:
            </strong>
          </>
        }
        items={[
          {
            heading: 'Desenvolvimento e modernização de soluções tecnológicas',
            text: 'Contrate equipes para a gestão e desenvolvimento de produtos digitais inovadores. Faça melhorias técnicas e funcionais em soluções já existentes com novas features ou conte com a inteligência da Exacta para tirar suas ideias do papel.'
          },
          {
            heading: 'Soluções web em nuvem para alta performance',
            text: 'Tenha plataformas escaláveis, seguras e altamente eficientes na sua empresa, com acesso rápido às aplicações e dados. Reduza custos e qualifique a experiência dos usuários com nossos squads especialistas.'
          },
          {
            heading: 'Integração de sistemas e plataformas',
            text: 'Aloque profissionais estratégicos para otimizar sua infraestrutura e conquistar mais sinergia entre suas tecnologias. Maximize a eficiência operacional e potencialize o fluxo de dados para impulsionar o seu desempenho empresarial.'
          },

          {
            heading: 'Desenvolvimento Avançado de Microsserviços',
            text: 'Eleve sua empresa para um alto nível tecnológico com arquiteturas altamente escaláveis, permitindo a construção e implementação de sistemas distribuídos e modulares para atender às demandas dinâmicas do mercado.'
          },
          {
            heading: 'Soluções financeiras personalizadas',
            text: 'Contrate times dedicados para desenvolver múltiplas soluções financeiras, como criação de carteiras digitais, métodos de pagamento, Open Finance, soluções contra fraudes, conformidade regulatória, programas de fidelidade e muito mais.'
          }
        ]}
      />

      <Product.TriggerAction
        newTab={false}
        text="Conte com a expertise dos times de profissionais da Exactaworks para potencializar os resultados da sua empresa."
        action={{
          label: 'Fale com a gente',
          href: '#contato'
        }}
      />

      <Product.Benefits
        heading={
          <>
            Por que contratar nossos{' '}
            <span className="color--green semi-bold italic">
              times ágeis multidisciplinares?
            </span>
          </>
        }
        text="Conheça as vantagens de desenvolver suas soluções tecnológicas com a alocação de profissionais capacitados em diferentes áreas e potencializados por Inteligência Artificial:"
        items={[
          {
            icon: <Solutions />,
            heading: 'Frameworks exclusivos e de alta performance',
            text: 'Metodologia própria estruturada e testada, integrada ao uso de ferramentas de gestão de produtividade e melhores softwares do mercado.'
          },
          {
            icon: <Solutions />,
            heading: 'Gestão de times realizada pela Exactaworks',
            text: 'Liderança Tech fundamentada na O2, garantindo máxima produtividade, engajamento, baixo turnover e realocação de profissionais rápida e segura.'
          },
          {
            icon: <Solutions />,
            heading: 'Times ágeis 100% dedicados ao cliente',
            text: 'Squads multifuncionais com atendimento exclusivo, consultivo, escaláveis e disponíveis para diferentes demandas tecnológicas.'
          },
          {
            icon: <Solutions />,
            heading: 'Soluções e decisões orientadas por dados',
            text: 'Acompanhamento de métricas de eficiência e apresentação recorrente de relatórios de produtividade, com definições de OKRs, KPIs e oportunidades para escalar resultados.'
          }
        ]}
      />

      {/* <Product.CardSlider
        heading="Cases de sucesso"
        cards={[
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 28, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 27, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 26, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 25, 2024',
            image: Cover
          },
          {
            to: 'https://www2.exactaworks.com.br/',
            text: 'Donec egestas diam molestie leo ultricies, vitae semper libero facilisis. Nullam metus.',
            date: 'Mar 24, 2024',
            image: Cover
          }
        ]}
      /> */}

      {/* <Product.Methodology /> */}

      <Product.SloganBenefits />

      <Product.BigNumbers
        items={[
          {
            label: 'mais rápido em tarefas repetitivas',
            value: '96%'
          },
          {
            label: 'maior confiança em qualidade de código',
            value: '85%'
          },
          {
            label: 'maior agilidade em code reviews',
            value: '15%'
          },
          {
            label: 'maior velocidade de maneira geral',
            value: '55%'
          }
        ]}
      />

      <Product.ExactaFlowTrigger />

      <Product.TriggerAction
        newTab={false}
        text={
          <>
            Vamos acelerar? <br />
            Contrate agora nossos squads multidisciplinares{' '}
            <span className="emphasis">potencializados por IA</span>.
          </>
        }
        action={{
          label: 'Fale com a gente',
          href: '#contato'
        }}
      />

      <Product.Clients />

      <Product.Contact />

      <Posts tag="times-ageis" />

      <Product.Newsletter />
    </Layout>
  );
}

export default AgileTeams;
